@charset "utf-8";
/* fonts */
@font-face {
  font-family: 'NanumSquare';
  font-weight: 400;
  src: url('../fonts/NanumSquare.eot');
  src: url('../fonts/NanumSquare.eot?#iefix') format('embedded-opentype'),
  url('../fonts/NanumSquare.woff') format('woff'),
  url('../fonts/NanumSquare.ttf') format('truetype');
}
/* 
@font-face {
font-family: 'Nanum Myeongjo';
font-style: normal;
font-weight: 400;
src: url('../fonts/nanum-myeongjo-regular.eot');
src: local('NanumMyeongjo'),
     url('../fonts/nanum-myeongjo-regular.eot?#iefix') format('embedded-opentype'),
     url('../fonts/nanum-myeongjo-regular.woff2') format('woff2'),
     url('../fonts/nanum-myeongjo-regular.woff') format('woff'),
     url('../fonts/nanum-myeongjo-regular.ttf') format('truetype')
} */

/* Reset */
html,body,h1,h2,h3,h4,h5,h6,div,p,blockquote,pre,code,address,ul,ol,li,menu,nav,section,article,aside,
dl,dt,dd,table,thead,tbody,tfoot,label,caption,th,td,form,fieldset,legend,hr,input,button,textarea,object,figure,figcaption {margin:0;padding:0; /*outline:none;*/}
html, body{width:100%;height: 100%;}
html{-webkit-touch-callout:none; -webkit-user-select:none; -webkit-tap-highlight-color:rgba(0, 0, 0, 0); position: relative; min-height: 100%;}
body{width:100%; min-width:320px; min-height:100%; -webkit-text-size-adjust:none;word-wrap:break-word;word-break:break-all;}
body,input,select,textarea,button {border:none;font-size:18px; font-family:'NanumSquare', 'Nanum Gothic', sans-serif; color:#727272;}
ul,ol,li{list-style:none;}
table{width:100%;border-spacing:0;border-collapse:collapse;}
img,fieldset{border:0;}
address,caption,cite,code,em{font-style:normal;font-weight:normal;}
label,img,input,select,textarea,button{vertical-align:middle;}
caption,legend{line-height:0;font-size:1px;overflow:hidden;}
hr{display:none;}
main,header,section,nav,footer,aside,article,figure{display:block;}
a{color:#000;text-decoration:none;}
a:link,a:hover,a:active{text-decoration:none;}


.clear { zoom:1;clear:both;}
.clear:after { content:""; display:block; clear:both;}
.skip { position:absolute; left:0; top:-100px; width:100%; height:50px; background:#000; text-align:center; color:#fff; line-height:50px;}
.skip:focus { top:0;}

.container {
  width: 100%;
  height: 100%;
  /* background-color: #0a0a23;  */
  text-align: center;
}

.container img {
  width: 25%;
}

.barcontainer {
  width: 50%;
  height: 50%;
  /* background-color: #0a0a23;  */
  text-align: center;
}